export default {
  x: {
    xx: 0,
    yy: 0,
    tt: 0,
    xy: 0,
    xt: 0,
    yt: 0,
    x: 0,
    y: 0,
    t: 0
  },
  y: {
    xx: 0,
    yy: 0,
    tt: 0,
    xy: 0,
    xt: 0,
    yt: 0,
    x: 0,
    y: 0,
    t: 0
  }
};
